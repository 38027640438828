import React, { useState } from 'react'
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import image from "../../assets/images/courses/one.jpg";
import { Modal, Button } from 'react-bootstrap'
import HubspotContactForm from './hubspot/HubspotContactForm';

const CoursesPage = () => {
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <Layout pageTitle="KWEC | Course Details">
      <Navbar />
      <PageHeader title="Course Details" />
      <section className="course-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="course-details__content">
              <div className="course-details__top">
                <div className="course-details__top-left">
                  <h2 className="course-details__title">Professional Development</h2>
                </div>
                <div className="course-details__top-right">
                  <a className="course-one__category">
                    For schools
                  </a>
                </div>
              </div>
              <div className="course-one__image">
                <img src={image} alt=""  width={100}/>
              </div>
              <div className="tab-content course-details__tab-content ">
                <div
                  className="tab-pane show active  animated fadeInUp"
                  role="tabpanel"
                  id="overview"
                >
                  <p className="course-details__tab-text">Being an early childhood educator is no child’s play! Apart from teaching skills and knowledge of the curriculum, educators have to hone various applied skills that pertain to interactive teaching methods.  </p>
                  <br/>
                  <h3>
                  Enroll in the following professional development courses that we offer, to get a competitive edge in the arena of early childhood education. 
                  </h3>
                  <ul>
                                    <li>Behaviour management techniques</li>
                                    <li>My Classroom Décor</li>
                                    <li>STEAM Education</li>
                                    <li>Tech savvy kids – To develop online games &amp; activities for children </li>
                                    <li>Yummy Tummy - Food &amp; Nutrition for children</li>
                                    <li>Tumbling Toons - Yoga for toddlers, Music &amp; Movement</li>
                                    <li>Little Explorers – Activities through Sensorial learning </li>
                                    <li>Budding Artists – Art &amp; Craft activities for children</li>
                                    <li>Art of Storytelling</li>
                                </ul>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 ">
            <div className="course-details__price">
              <p className="course-details__price-text">
                Talk to our executive and enroll now!{' '}
              </p>
              <Button
                onClick={handleShow}
                className="thm-btn course-details__price-btn"
                variant="default"
              >
                Enquire Now
              </Button>
            </div>
          </div>
          <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enquiry Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HubspotContactForm/>
              </Modal.Body>
            </Modal>
        </div>
      </div>
    </section>
      <Footer />
    </Layout>
  );
};

export default CoursesPage;
